import { createSlice } from "@reduxjs/toolkit";

const initialEmailsSubState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  emailSubForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const emailsSubSlice = createSlice({
  name: "emailsSub",
  initialState: initialEmailsSubState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    emailSubFetched: (state, action) => {
      state.actionsLoading = false;
      state.emailSubForEdit = action.payload.emailSubForEdit;
    },
    // findCustomers
    emailsSubFetched: (state, action) => {
      const { totalCount, result } = action.payload;
      state.listLoading = false;
      state.entities = result;
      state.totalCount = totalCount;
    },

    // updateCustomer
    emailsSubUpdated: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.subscription_id === action.payload.results.subscription_id) {
          return action.payload.emailsubscription;
        }
        return entity;
      });
    },

    // deleteCustomer
    emailSubDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.id !== action.payload.id);
    },
    // deleteCustomers
    emailsSubDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
  },
});
