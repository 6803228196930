import * as requestFromServer from "./profileCrud";
import { profileSlice, callTypes } from "./profileSlice";
import { toast } from "react-toastify";

const { actions } = profileSlice;

export const fetchProfile = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProfile(ids)
    .then((response) => {
      const result = response.data;
      dispatch(actions.profileFetched({ result }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      throw error;
    });
};

export const updateProfile = (profile) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProfile(profile)
    .then((response) => {
      let results = response.data;
      toast.success("Profile Updated Successfully!");
      dispatch(actions.profileUpdated({ results }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const updatePassword = (profile) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePassword(profile)
    .then((response) => {
      toast.success("Password Changed Successfully!");
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};
