/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_helpers';
import { NavLink } from 'react-router-dom';

export function MixedWidget1({ className, entities }) {
  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Body */}
      <div className="card-body p-0 position-relative overflow-hidden">
        {/* Stat */}
        <div className="card-spacer">
          <div className="row m-0">
            <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 ">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/Media/Equalizer.svg')}
                ></SVG>
                <span className="float-right symbol symbol-light-warning symbol-45">
                  <span className=" symbol-label font-weight-bolder font-size-h1 ">
                    {entities && entities.countModel.countCategory}
                  </span>
                </span>
              </span>
              <NavLink className="menu-link" to="/portfolio/category">
                <span className="text-warning font-weight-bold font-size-h6 text-uppercase">
                  Total Category
                </span>
              </NavLink>
            </div>
            <div className="col bg-light-primary px-6 py-8 rounded-xl mr-7">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/Design/Edit.svg')}
                ></SVG>
                <span className="float-right symbol symbol-light-primary symbol-45">
                  <span className=" symbol-label font-weight-bolder font-size-h1 ">
                    {entities && entities.countModel.countProject}
                  </span>
                </span>
              </span>
              <NavLink className="menu-link" to="/portfolio/category">
                <span className="text-primary font-weight-bold font-size-h6 mt-2 text-uppercase">
                  total project
                </span>
              </NavLink>
            </div>
            <div className="col bg-light-success px-6 py-8 rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/Communication/Urgent-mail.svg'
                  )}
                ></SVG>
                <span className="float-right symbol symbol-light-success symbol-45">
                  <span className=" symbol-label font-weight-bolder font-size-h1 ">
                    {entities && entities.countModel.countImage}
                  </span>
                </span>
              </span>
              <a className="text-success font-weight-bold font-size-h6 mt-2 text-uppercase">
                total image
              </a>
            </div>
          </div>
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: '411px', height: '461px' }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}
