import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  listLoading: false,
  actionsLoading: false,
  //totalCount: 0,
  entities: null,
  roles: null,
  userForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    userFetched: (state, action) => {
      state.actionsLoading = false;
      state.userForEdit = action.payload.userForEdit;
    },
    // findCustomers
    userListFetched: (state, action) => {
      const { totalCount, result } = action.payload;
      state.listLoading = false;
      state.entities = result;
      state.totalCount = totalCount;
    },
    // createCustomer
    userCreated: (state, action) => {
      state.actionsLoading = false;
      state.entities.push(action.payload.results);
    },
    // updateCustomer
    userUpdated: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.results.id) {
          return action.payload.results;
        }
        return entity;
      });
    },
    // deleteCustomer
    userDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.id !== action.payload.id);
    },
    //Roles
    rolesFetched: (state, action) => {
      state.listLoading = false;
      state.roles = action.payload;
    },

    userPassword: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.results.id) {
          return action.payload.results;
        }
        return entity;
      });
    },
    // userUpdateState
    userStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
