import Swal from "sweetalert2/dist/sweetalert2.js";

export const showSwal = (error) => {
  Swal.fire({
    heightAuto: false,
    icon: "error",
    title: "Oops...",
    text: error,
  });
};

export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
  { text: "200", value: 200 },
  { text: "500", value: 500 },
];
export const initialFilter = {
  filter: {},
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10,
};
