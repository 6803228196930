import { createSlice } from "@reduxjs/toolkit";

const initialProfileState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  categoryForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findCustomers
    profileFetched: (state, action) => {
      const { result } = action.payload;
      let profileData = {
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
      };
      if (result && result.fullName) {
        profileData.email = result.email;
        profileData.userName = result.userName;

        const name = result.fullName.split(" ");
        if (name.length === 2) {
          profileData.firstName = name[0];
          profileData.lastName = name[1];
        } else {
          profileData.firstName = result.fullName;
        }
      }
      state.listLoading = false;
      state.entities = profileData;
    },
    // updateCustomer
    categoryUpdated: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.category_id === action.payload.results.category_id) {
          return action.payload.results;
        }
        return entity;
      });
    },
  },
});
