// import { environment } from "../environments/environment";
// export default function setupAxios(axios, store) {
//   axios.interceptors.request.use(
//     (config) => {
//       const {
//         auth: { accessToken },
//       } = store.getState();
//       if (accessToken) {
//         config.headers.Authorization = `Bearer ${accessToken}`;
//       }
//       return config;
//     },
//     (error) => Promise.reject(error)
//   );

//   axios.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     (error) => {
//       let errorMsg = [];

//       if (error.response && error.response.data) {
//         if (error.response.data.errors) {
//           for (const key in error.response.data.errors) {
//             errorMsg.push(` ${error.response.data.errors[key]}`);
//           }
//         } else {
//           errorMsg.push(error.response.data);
//         }
//         console.log("errorMsg1", errorMsg);
//         return Promise.reject(errorMsg);
//       } else {
//         //handle other errors that not inside error.response.data
//         if (error && error === "Network Error") {
//           //handle the network error
//           errorMsg.push("The network connection failed, Please retry and still persist error contact administrator.");
//         } else {
//           console.log("Something went wrong");
//           //handle other errors
//           errorMsg.push("Something went wrong, Please retry and still persist error contact administrator.");
//         }
//         console.log("errorMsg2", errorMsg);
//         return Promise.reject(errorMsg);
//       }
//     }
//   );

//  }
import { environment } from "../environments/environment";
import { toast } from "react-toastify";
export default function setupAxios(axios, store) {
  // let isRefreshing = false;
  // let failedQueue = [];

  // const processQueue = (error, token = null) => {
  //   failedQueue.forEach((prom) => {
  //     if (error) {
  //       prom.reject(error);
  //     } else {
  //       prom.resolve(token);
  //     }
  //   });
  //   failedQueue = [];
  // };

  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { accessToken },
      } = store.getState();

      const a = JSON.parse(localStorage.getItem("persist:skillTrial-auth"));
      if (accessToken && a) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      const lang = localStorage.getItem("i18nextLng");
      config.headers["Accept-Language"] = lang;

      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // let errorMsg = [];
      let errorMsg = "";
      const originalRequest = error.config;

      if (error && error.response && error.response.status && originalRequest && error.response.status === 401 && !originalRequest._retry) {
        console.log("Unauthorise access 401 error");
        window.location.href = "/logout";

      } else if (error.response && error.response.data) {
        if (error.response.data.errors) {
          console.log("error", error.response.data);
          for (const key in error.response.data.errors) {
            // errorMsg.push(` ${error.response.data.errors[key]}`);
            errorMsg += error.response.data.errors[key] + ",";
          }
        } else {
          // errorMsg.push(error.response.data);
          console.log("error", error.response.data);
          errorMsg += error.response.data;
        }
        console.log("errorMsg1", errorMsg);
        toast.error(errorMsg);
        return Promise.reject(errorMsg);
        
      } else {
        //handle other errors that not inside error.response.data
        if (error && error === "Network Error") {
          //handle the network error
          // errorMsg.push("The network connection failed, Please retry and still persist error contact administrator.");
          errorMsg = "The network connection failed, Please retry and still persist error contact administrator.";
        } else {
          console.log("Something went wrong");
          //handle other errors
          // errorMsg.push("Something went wrong, Please retry and still persist error contact administrator.");
          errorMsg = "Something went wrong, Please retry and still persist error contact administrator.";
        }
        // Swal.fire({
        //   heightAuto: false,
        //   icon: "error",
        //   title: "Oops...",
        //   text: errorMsg,
        // });
        console.log("errorMsg2", errorMsg);
        toast.error(errorMsg);
        return Promise.reject(errorMsg);
      }
    }
  );
}
