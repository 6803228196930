import { createSlice } from "@reduxjs/toolkit";

const initialProjectsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  projectForEdit: undefined,
  categories: null,
  companies: null,
  allimages: null,
  file: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState: initialProjectsState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    projectFetched: (state, action) => {
      state.actionsLoading = false;
      state.projectForEdit = action.payload.projectForEdit;
    },
    // findCustomers
    projectsFetched: (state, action) => {
      const { totalCount, result } = action.payload;
      state.listLoading = false;
      state.entities = result;
      state.totalCount = totalCount;
    },
    // findImages
    ImagesFetched: (state, action) => {
      const { result } = action.payload;
      state.listLoading = false;
      state.allimages = result;
    },
    // findCustomers
    categoriesFetched: (state, action) => {
      const { result } = action.payload;
      state.listLoading = false;
      state.categories = result;
    },
    //
    companiesFetched: (state, action) => {
      const { result } = action.payload;
      state.listLoading = false;
      state.companies = result;
    },

    //
    // createCustomer
    projectCreated: (state, action) => {
      state.actionsLoading = false;
      state.entities.push(action.payload.project);
    },
    // updateCustomer
    projectUpdated: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.project_id === action.payload.result.project_id) {
          return action.payload.result;
        }
        return entity;
      });
    },
    // deleteCustomer
    projectDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.id !== action.payload.id);
    },
    // deleteImages
    ImagesDeleted: (state, action) => {
      state.actionsLoading = false;
      state.allimages = state.allimages.filter((el) => el.id !== action.payload.id);
    },
    // deleteCustomers
    projectsDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
    // projectsUpdateState
    projectsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

    attachImageUploadModal: (state, action) => {
      state.file = action.payload;
    },
  },
});
