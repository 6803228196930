import axios from "axios";
import { environment } from "../../../../environments/environment";

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProfile(id) {
  return axios.get(`${environment.ApiUrl}/Auth`);
}

// UPDATE => PUT: update the category on the server
export function updateProfile(profile) {
  profile.firstName = profile.firstName.trim();
  profile.lastName = profile.lastName.trim();
  return axios.post(`${environment.ApiUrl}/Auth`, profile);
}

export function updatePassword(password) {
  const user = {
    oldPassword: password.current_password,
    newPassword: password.new_password,
  };
  return axios.post(`${environment.ApiUrl}/Auth/ChangePassword`, user);
}
