import axios from "axios";
import { environment } from "../../../../environments/environment";

export const LOGIN_URL = `${environment.ApiUrl}/Auth/login`;
export const FORGOT_PASSWORD_URL = `${environment.ApiUrl}/Auth/ForgetPassword`;
export const Reset_PASSWORD_URL = `${environment.ApiUrl}/Auth/ResetPassword`;

export function login(email, password) {
  const user = {
    username: email,
    password: password,
  };
  return axios.post(LOGIN_URL, user);
}

export function requestPassword(email) {
  return axios.post(FORGOT_PASSWORD_URL, { email });
}

export function changePassword(params) {
  return axios.post(Reset_PASSWORD_URL, params);
}
