import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { updatePassword } from "../../../../api/Profile/profile";
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";

// Validation schema
const PasswordSchema = Yup.object().shape({
  current_password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Current Password is required."),
  new_password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("New Password is required."),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Password must match.")
    .required("Confirm Password is required."),
});

export function ResetPassword() {
  const dispatch = useDispatch();
  return (
    <>
      <CardHeader title="Reset Password">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Formik
          enableReinitialize={true}
          initialValues={{
            current_password: "",
            new_password: "",
            confirm_password: "",
          }}
          validationSchema={PasswordSchema}
          onSubmit={(values) => {
            updatePassword(values)
              .then((response) => {
                if (response) {
                  toast.success("Password updated successfully.");
                  const frmResetPassword = document.getElementById("frmResetPassword");
                  frmResetPassword.reset();
                }
              })
              .catch((error) => {
                toast.error(error);
              });
          }}
        >
          {({ handleSubmit, errors, values, handleChange, touched }) => (
            <Form onSubmit={handleSubmit} id="frmResetPassword">
              <div className="form-group ">
                <div className="form-group ">
                  <label htmlFor="current_password">Current Password</label>
                  <Field name="current_password" type="password" className={"form-control" + (errors.current_password && touched.current_password ? " is-invalid" : "")} />
                  <ErrorMessage name="current_password" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group ">
                  <label htmlFor="new_password">New Password</label>
                  <Field name="new_password" type="password" className={"form-control" + (errors.new_password && touched.new_password ? " is-invalid" : "")} />
                  <ErrorMessage name="new_password" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group ">
                  <label htmlFor="confirm_password">Confirm Password</label>
                  <Field name="confirm_password" type="password" className={"form-control" + (errors.confirm_password && touched.confirm_password ? " is-invalid" : "")} />
                  <ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />
                </div>
              </div>
              <div className="float-right">
                <> </>
                <button type="submit" className="btn btn-primary btn-elevate mr-1">
                  Reset Password
                </button>
                <> </>
                <button type="reset" className="btn btn-secondary">
                  Reset
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </>
  );
}
