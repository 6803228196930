import React from "react";
import {Demo1Dashboard} from "./Demo1Dashboard";


export function Dashboard() {

    return <>
        <Demo1Dashboard />
    </>;
}
