import { useQuery } from "@tanstack/react-query";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { getProfile, updateProfile } from "../../../../api/Profile/profile";
import { showSwal } from "../../../../constants/Common";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { ResetPassword } from "./resetPassword";

// Validation schema
const ProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First Name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  userName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("UserName is required"),
});

export function Profile() {
  // Categories Redux state
  const { currentState, authState } = useSelector((state) => ({ currentState: state.profile, authState: state.auth.user }), shallowEqual);

  const profileValue = {
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
  };

  const profileListQuery = useQuery({
    queryKey: [`profileListQuery`, authState.userId],
    queryFn: () => getProfile(authState.userId).catch((error) => showSwal(error)),
  });

  if (profileListQuery.data) {
    const { fullName } = profileListQuery.data;
    const name = fullName.split(" ");
    profileValue.firstName = name[0];
    profileValue.lastName = name[1];
    profileValue.email = profileListQuery.data?.email;
    profileValue.userName = profileListQuery.data?.userName;
  }

  return (
    <>
      <Card>
        <CardHeader title="Profile Details">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Formik
            enableReinitialize={true}
            initialValues={profileValue}
            validationSchema={ProfileSchema}
            onSubmit={(values) => {
              updateProfile(values).then((response) => {
                if (response) {
                  toast.success("Profile Updated Successfully");
                }
              });
            }}
          >
            {({ handleSubmit, errors, values, handleChange, touched }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group row">
                  <div className="form-group col-md-6">
                    <label htmlFor="firstName">First Name</label>
                    <Field name="firstName" type="text" className={"form-control" + (errors.firstName && touched.firstName ? " is-invalid" : "")} />
                    <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="lastName">Last Name</label>
                    <Field name="lastName" type="text" className={"form-control" + (errors.lastName && touched.lastName ? " is-invalid" : "")} />
                    <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="email">Email Id</label>
                    <Field name="email" type="email" className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")} />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="user_name">UserName</label>
                    <Field name="userName" type="text" className={"form-control" + (errors.userName && touched.userName ? " is-invalid" : "")} />
                    <ErrorMessage name="userName" component="div" className="invalid-feedback" />
                  </div>
                </div>
                <> </>
                <div className="float-right">
                  <button type="submit" className="btn btn-primary btn-elevate mr-1">
                    Save
                  </button>
                  <> </>
                  <button type="reset" className="btn btn-secondary">
                    Reset
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
        <ResetPassword />
      </Card>
    </>
  );
}
