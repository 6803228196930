import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  categoryForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findCustomers
    dashboardFetched: (state, action) => {
      const { result } = action.payload;
      state.listLoading = false;
      state.entities = result;
    },
  },
});
