import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted` : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Portfolio</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li className={`menu-item ${getMenuItemActive("/portfolio/category", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/portfolio/category">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
            </span>
            <span className="menu-text">Categories</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/portfolio/projects", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/portfolio/projects">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Pencil.svg")} />
            </span>
            <span className="menu-text">Projects</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/portfolio/Company", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/portfolio/Company">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
            <span className="menu-text">Company</span>
          </NavLink>
        </li>
        {/* end:: section */}
        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Contact us</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/contactus/contacts", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/contactus/contacts">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Adress-book2.svg")} />
            </span>
            <span className="menu-text">Contact</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/contactus/emailsubscription", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/contactus/emailsubscription">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")} />
            </span>
            <span className="menu-text">Email Subscription</span>
          </NavLink>
        </li>
        {/* Member Pages */}
        {/*begin::1 Level*/}
        <li className="menu-section ">
          <h5 className="menu-text">Candidate</h5>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li className={`menu-item ${getMenuItemActive("/candidate/job", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/candidate/job">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text">Job</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/candidate/candidate", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/candidate/candidate">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text">Candidate</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/candidate/technology", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/candidate/technology">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Shield-user.svg")} />
            </span>
            <span className="menu-text">Technology</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/candidate/interview", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/candidate/interview">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Shield-user.svg")} />
            </span>
            <span className="menu-text">Interview</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/candidate/offer", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/candidate/offer">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Shield-user.svg")} />
            </span>
            <span className="menu-text">Offer</span>
          </NavLink>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text">Administration</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item menu-item-submenu ${getMenuItemActive("/error", true)}`} aria-haspopup="true" data-menu-toggle="hover">
          <NavLink className="menu-link menu-toggle" to="/master">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">Administration</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Masters</span>
                </span>
              </li>
              <li className={`menu-item ${getMenuItemActive("/administration/users", false)}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/administration/users">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Users</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/administration/roles", false)}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/administration/roles">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Roles</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </>
  );
}
