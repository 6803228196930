import { createSlice } from "@reduxjs/toolkit";

const initialCategoriesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  contactForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState: initialCategoriesState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    contactFetched: (state, action) => {
      state.actionsLoading = false;
      state.contactForEdit = action.payload.contactForEdit;
    },
    // findCustomers
    contactsFetched: (state, action) => {
      const { totalCount, result } = action.payload;
      state.listLoading = false;
      state.entities = result;
      state.totalCount = totalCount;
    },

    // deleteCustomer
    contactDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.id !== action.payload.id);
    },
    // deleteCustomers
    contactsDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
  },
});
