import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import { categoriesSlice } from '../app/modules/Portfolio/_redux/categories/categoriesSlice';
import { projectsSlice } from '../app/modules/Portfolio/_redux/projects/projectsSlice';
import { contactsSlice } from '../app/modules/ContactUs/_redux/contacts/contactsSlice';
import { emailsSubSlice } from '../app/modules/ContactUs/_redux/emailsSub/emailsSubSlice';
import { profileSlice } from '../app/modules/Profile/_redux/profileSlice';
import { dashboardSlice } from '../app/modules/Dashboard/_redux/dashboardSlice';
import {companySlice}   from '../app/modules/Portfolio/_redux/company/companySlice';
 import {userSlice}   from '../app/modules/Administration/_redux/users/userSlice';
 import {roleSlice}   from '../app/modules/Administration/_redux/roles/roleSlice';
export const rootReducer = combineReducers({
  auth: auth.reducer,
  categories: categoriesSlice.reducer,
  projects: projectsSlice.reducer,
  contacts: contactsSlice.reducer,
  emailsSub: emailsSubSlice.reducer,
  profile: profileSlice.reducer,
  dashboard: dashboardSlice.reducer,
  company: companySlice.reducer,
  users: userSlice.reducer,
  roles:roleSlice.reducer,

});

export function* rootSaga() {
  yield all([auth.saga()]);
}
