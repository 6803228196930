import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getDashboardList } from "../../../../api/Profile/profile";
import { showSwal } from "../../../../constants/Common";
import { MixedWidget1 } from "../../../../_metronic/_partials/widgets";
import { DashChart } from "./DashChart";

export function Demo1Dashboard() {
  const dashboardListQuery = useQuery({
    queryKey: [`dashboardListQuery`],
    queryFn: () => getDashboardList().catch((error) => showSwal(error)),
  });

  console.log(dashboardListQuery, "dashboardListQuery");
  return (
    <>
      <div className="">
        <MixedWidget1 className="card-stretch gutter-b" entities={dashboardListQuery.data} />
        <DashChart entities={dashboardListQuery.data} />
      </div>
    </>
  );
}
