import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
};

const initialAuthState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
};

export const reducer = persistReducer({ storage, key: "skillTrial-auth", whitelist: ["user", "accessToken", "refreshToken"] }, (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      const { accessToken, refreshToken, user } = action.payload;
      return { ...state, accessToken, user: user, refreshToken };
    }

    case actionTypes.Logout: {
      // TODO: Change this code. Actions in reducer aren't allowed.
      return initialAuthState;
    }

    default:
      return state;
  }
});

export const actions = {
  login: (accessToken, refreshToken, user) => ({ type: actionTypes.Login, payload: { accessToken, refreshToken, user } }),
  logout: () => ({ type: actionTypes.Logout }),
};

export function* saga() {}
