import { createSlice } from "@reduxjs/toolkit";

const initialRoleState = {
  listLoading: false,
  actionsLoading: false,
  //totalCount: 0,
  entities: null,
  roleForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const roleSlice = createSlice({
  name: "role",
  initialState: initialRoleState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    roleFetched: (state, action) => {
      state.actionsLoading = false;
      state.roleForEdit = action.payload.roleForEdit;
    },
    // findCustomers
    roleListFetched: (state, action) => {
      const { totalCount, result } = action.payload;
      state.listLoading = false;
      state.entities = result;
      state.totalCount = totalCount;
    },
    // createCustomer
    roleCreated: (state, action) => {
      state.actionsLoading = false;
      state.entities.push(action.payload.results);
    },
    // updateCustomer
    roleUpdated: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.results.id) {
          return action.payload.results;
        }
        return entity;
      });
    },
    // deleteCustomer
    roleDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.id !== action.payload.id);
    },
    // userUpdateState
    roleStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
