import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { Profile } from "./modules/Profile/pages/profile";

const PortfolioPage = lazy(() => import("./modules/Portfolio/pages/portfolioPage"));
const ContactUsPage = lazy(() => import("./modules/ContactUs/pages/contactUsPage"));
const AdministrationPage = lazy(() => import("./modules/Administration/pages/administrationPage"));
const CandidatePage = lazy(() => import("./modules/Candidate/pages/candidatePage"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/profile" component={Profile} />
        <Route path="/portfolio" component={PortfolioPage} />
        <Route path="/contactus" component={ContactUsPage} />
        <ContentRoute path="/candidate" component={CandidatePage} />
        <Route path="/administration" component={AdministrationPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
