import { createSlice } from "@reduxjs/toolkit";

const initialCompanyState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  companyForEdit: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const companySlice = createSlice({
  name: "company",
  initialState: initialCompanyState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    companyFetched: (state, action) => {
      state.actionsLoading = false;
      state.companyForEdit = action.payload.companyForEdit;
    },
    // findCustomers
    companyListFetched: (state, action) => {
      const { totalCount, result } = action.payload;
      state.listLoading = false;
      state.entities = result;
      state.totalCount = totalCount;
    },
    // createCustomer
    companyCreated: (state, action) => {
      state.actionsLoading = false;
      state.entities.push(action.payload.results);
    },
    // updateCustomer
    companyUpdated: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.company_id === action.payload.results.company_id) {
          return action.payload.results;
        }
        return entity;
      });
    },
    // deleteCustomer
    companyDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.id !== action.payload.id);
    },
    // deleteCustomers
    companiesDeleted: (state, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
    // companyUpdateState
    companyStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
