import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect, Switch } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { changePassword } from "../_redux/authCrud";
import { toast } from "react-toastify";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

function ChangePassword(props) {
  const history = useHistory();
  const queryStringParams = queryString.parse(props.location.search);

  const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required("New Password is required."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Password must match.")
      .required("Confirm Password is required."),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      const data = {
        email: queryStringParams.email,
        token: queryStringParams.token,
        newPassword: values.newPassword,
      };
      changePassword(data)
        .then(() => {
          // show success message & redirect to login
          toast.success("Password Change Successfully");
          // <Redirect to="/auth/login" />
          history.push("/auth/login");
        })
        .catch(() => {});
    },
  });

  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Reset Password ?</h3>
          <div className="text-muted font-weight-bold">Enter your New Password and Confirm Password </div>
        </div>
        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="Password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("newPassword")}`}
              name="newPassword"
              {...formik.getFieldProps("newPassword")}
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.newPassword}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              type="Password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("confirmPassword")}`}
              name="confirmPassword"
              {...formik.getFieldProps("confirmPassword")}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.confirmPassword}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button id="kt_login_forgot_submit" type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" disabled={formik.isSubmitting}>
              Submit
            </button>
            <Link to="/auth">
              <button type="button" id="kt_login_forgot_cancel" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ChangePassword));
//Abce@123
