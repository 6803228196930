import axios from "axios";
import { environment } from "../../environments/environment";

// get profile details by user id
export async function getProfile() {
  const response = await axios.get(`${environment.ApiUrl}/Auth`);
  return response.data;
}

// UPDATE => PUT: update the category on the server
export async function updateProfile(profile) {
  profile.firstName = profile.firstName.trim();
  profile.lastName = profile.lastName.trim();
  const response = await axios.post(`${environment.ApiUrl}/Auth`, profile);
  return response.data;
}

export async function updatePassword(password) {
  const user = {
    oldPassword: password.current_password,
    newPassword: password.new_password,
  };
  const response = await axios.post(`${environment.ApiUrl}/Auth/ChangePassword`, user);
  return response.data;
}

export async function getDashboardList() {
  const response = await axios.get(`${environment.ApiUrl}/dashboard/get`);
  return response.data;
}
