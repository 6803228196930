import ApexCharts from "apexcharts";
import React, { useEffect } from "react";

export function DashChart({ entities }) {
  const cat_name = [];
  const values = [];

  var optionsss = {
    series: [
      {
        name: "Project",
        data: values,
      },
    ],
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e);
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: cat_name,
      labels: {
        style: {
          colors: "black",
          fontSize: "12px",
        },
      },
    },
  };

  entities &&
    entities.countCategoryModel.map((cat) => {
      cat_name.push(cat.category_name);
      return values.push(cat.countProject);
    });
  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_1_chart");
    if (!element) {
      return;
    }
    const chart = new ApexCharts(element, optionsss);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [optionsss]);
  return (
    <div
      id="kt_mixed_widget_1_chart"
      className=""
      style={{
        height: "250px",
        backgroundColor: "white",
        borderRadius: "0.42rem",
      }}
    ></div>
  );
}
